<template>
<ModalMainNew
  show-close-mobile
  secondary-background-color
  @close="close"
>
  <div class="px-2">
    <h2 class="font-bold mt-[-26px] pb-4">Game Volatility</h2>
    <p class="text-sm text-[#5F677C] pb-3">Indicator of volatility of the game, that more lights are burning the higher payouts gives the game but also more risky is.</p>
    <div
      v-for="data in volitiliyData"
      :key="data.heading"
      class="pb-2"
    >
      <h3
        :class="{
          'text-[#17C36B]' : data.heading === 'Minimal',
          'text-[#9CCF40]' : data.heading === 'Low',
          'text-[#EDBE00]' : data.heading === 'Medium',
          'text-[#EB9632]' : data.heading === 'High',
          'text-[#E23E44]' : data.heading === 'Maximum'
        }"
        class="text-sm font-bold pb-1"
      >
        <img
          :src="data.icon"
          class="inline-block mr-1"
          width="25"
          height="20"
        >
        {{ data.heading }}
      </h3>
      <p class="text-xs text-[#5F677C] sm:text-sm">{{ data.description }}</p>
    </div>
  </div>
</ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import VolOne from '~/assets/img/volatility/volatility-1-min.svg';
import VolTwo from '~/assets/img/volatility/volatility-2-low.svg';
import VolThree from '~/assets/img/volatility/volatility-3-medium.svg';
import VolFour from '~/assets/img/volatility/volatility-4-high.svg';
import VolFive from '~/assets/img/volatility/volatility-5-max.svg';

const uiStore = useUiStore();
const { showVolatilityModal, } = storeToRefs(uiStore);

const volitiliyData = [
  {
    heading: 'Minimal',
    icon: VolOne,
    description: 'Minimal volatility games offer consistent, smaller wins without big swings in outcomes.',
  },
  {
    heading: 'Low',
    icon: VolTwo,
    description: 'Low volatility games give you moderate thrills with occasional bigger payouts.',
  },
  {
    heading: 'Medium',
    icon: VolThree,
    description: 'Moderate volatility games provide a mix of smaller and larger wins, keeping the gameplay exciting',
  },
  {
    heading: 'High',
    icon: VolFour,
    description: 'High volatility games offer the chance for thrilling, significant wins, providing a rush of excitement with each spin or play.',
  },
  {
    heading: 'Maximum',
    icon: VolFive,
    description: 'Maximum volatility games provide rare but massive wins, creating an electrifying experience with the potential for life-changing payouts.',
  },
];

function close() {
  showVolatilityModal.value = false;
}
</script>
